import React from 'react';
import { Button } from 'rebass';
import { FormikProps, Field } from 'formik';
import {
  FormValues,
  ReasonMedicareMayNotPay,
} from 'containers/Files/AbnFormRequestButton/constants';
import { FormikTextareaField } from 'components/forms/FormikTextareaField';
import {
  LabelledRadioFieldGroup,
  Option,
} from 'components/forms/LabelledRadioFieldGroup';

type Props = FormikProps<FormValues>;

const rawMayNotPayOptions: ReasonMedicareMayNotPay[] = [
  ReasonMedicareMayNotPay.ExceededMaxTreatment,
  ReasonMedicareMayNotPay.NotPrescribed,
  ReasonMedicareMayNotPay.ExcessiveTreatment,
  ReasonMedicareMayNotPay.RequestingAdditionalSessions,
  ReasonMedicareMayNotPay.NotMedicallyNecessary,
];
const mayNotPayOptions: Option[] = rawMayNotPayOptions.map(option => ({
  label: option,
  value: option,
}));

const billingOptions: Option[] = [
  {
    label: 'Option 1',
    value: '1',
    subText:
      'I want the physical therapist listed above. You may ask\n' +
      '              to be paid now, but I also want Medicare billed for an\n' +
      '              official decision on payment, which is sent to me on a\n' +
      '              Medicare Summary Notice (MSN). I understand that if\n' +
      "              Medicare doesn't pay, I am responsible for payment, but\n" +
      '              I can appeal to Medicare by following the directions on\n' +
      '              the MSN. If Medicare does pay, you will refund any\n' +
      '              payments I made to you, less co-pays or deductibles.',
  },
  {
    label: 'Option 2',
    value: '2',
    subText:
      'I want the physical therapist listed above, but do not\n' +
      '              bill Medicare. You may ask to be paid now as I am\n' +
      '              responsible for payment. I cannot appeal if Medicare is\n' +
      '              not billed.',
  },
  {
    label: 'Option 3',
    value: '3',
    subText:
      'I don’t want the physical therapist listed above. I\n' +
      '              understand with this choice I am not responsible for\n' +
      '              payment, and I cannot appeal to see if Medicare would\n' +
      '              pay.',
  },
];

export const AbnFormRequestForm = ({ handleSubmit, isValid }: Props) => {
  return (
    <form style={{ height: 'calc(100% - 65px)' }} onSubmit={handleSubmit}>
      <div style={{ height: '100%', overflow: 'auto' }}>
        <fieldset
          style={{
            border: 'none',
            padding: 0,
            paddingTop: 10,
            marginBottom: 15,
          }}
        >
          <legend style={{ fontWeight: 'bold' }}>Physical Therapy</legend>
          <Field
            name="physicalTherapyActivity"
            component={FormikTextareaField}
            placeholder="Please write the services and/or modalities provided"
            style={{
              width: '100%',
              borderRadius: 4,
              borderColor: '#aaa',
              height: 120,
              outline: 'none',
              padding: 8,
              resize: 'none',
            }}
          />
        </fieldset>
        <fieldset
          style={{
            border: 'none',
            padding: 0,
            paddingTop: 10,
            marginBottom: 15,
          }}
        >
          <LabelledRadioFieldGroup
            headerText={
              <legend style={{ fontWeight: 'bold' }}>
                Reason Medicare May Not Pay
              </legend>
            }
            options={mayNotPayOptions}
            fieldName="reasonMedicareMayNotPay"
            padding={0}
          />
        </fieldset>
        <fieldset
          style={{
            border: 'none',
            padding: 0,
            paddingTop: 10,
            marginBottom: 15,
          }}
        >
          <LabelledRadioFieldGroup
            headerText={
              <legend style={{ fontWeight: 'bold' }}>
                Medicare Billing Preference
              </legend>
            }
            options={billingOptions}
            fieldName="medicareBillingPreference"
            padding={0}
          />
        </fieldset>
        <fieldset
          style={{
            border: 'none',
            padding: 0,
            paddingTop: 10,
            marginBottom: 15,
          }}
        >
          <legend style={{ fontWeight: 'bold' }}>Additional Information</legend>
          <Field
            name="additionalInformation"
            component={FormikTextareaField}
            style={{
              width: '100%',
              borderRadius: 4,
              borderColor: '#aaa',
              height: 120,
              outline: 'none',
              padding: 8,
              resize: 'none',
            }}
          />
        </fieldset>
      </div>
      <Button
        type="submit"
        marginTop="30px"
        width="100%"
        variant="secondary"
        disabled={!isValid}
      >
        Submit
      </Button>
    </form>
  );
};
