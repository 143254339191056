import React from 'react';
import { MissingDocumentsPopup } from 'components/MissingDocumentsPopup';
import {
  CLEAR_EVAL_SESSION_STATE,
  SKIP_START_EVAL_SESSION_WITH_ERRORS,
} from 'reducers/evalSession';
import { Text } from 'rebass';
import { useDispatch } from 'react-redux';
import { Appointment, Case, Lead, Patient } from 'types/types';
import { REQUIRED_DOCUMENTS } from 'pages/appointment/constants';

interface Props {
  isStartSessionDisabled: boolean;
  isSkippingStartErrors: boolean;
  appointment?: Appointment;
  lead?: Lead;
  patient?: Patient;
  therapyCase?: Case;
}

export const MissingDocumentHandler = ({
  appointment,
  isStartSessionDisabled,
  isSkippingStartErrors,
  lead,
  patient,
  therapyCase,
}: Props) => {
  const dispatch = useDispatch<any>();
  if (isStartSessionDisabled) {
    return (
      <MissingDocumentsPopup
        lead={lead}
        appointment={appointment}
        patient={patient}
        therapyCase={therapyCase}
        // @ts-ignore
        onClickOverlay={() => dispatch({ type: CLEAR_EVAL_SESSION_STATE })}
        // @ts-ignore
        onSkipWithErrors={notes =>
          dispatch({
            type: SKIP_START_EVAL_SESSION_WITH_ERRORS,
            payload: { notes },
          })
        }
        trackedDocuments={REQUIRED_DOCUMENTS}
      />
    );
  }
  if (isSkippingStartErrors) {
    return (
      <Text
        padding={2}
        textAlign={'center'}
        backgroundColor="#444"
        color="white"
        fontSize={1}
      >
        You're starting the session with documents missing.
      </Text>
    );
  }
  return null;
};
