import React, { useState } from 'react';
import { Button, Flex } from 'rebass';

interface Props {
  onCancelVisit: () => void;
  onProceedWithVisit: () => void;
}

export const HomeHealthWarningCheck = ({
  onCancelVisit,
  onProceedWithVisit,
}: Props) => {
  const [hasReceivedHomeHealth, setHasReceivedHomeHealth] = useState<
    'unset' | 'yes' | 'no'
  >('unset');

  return (
    <>
      <p style={{ fontSize: 13 }}>
        Have you received any in-home medical services since my last visit? This
        includes blood pressure checks, injections, medications, etc.
      </p>
      <Flex sx={{ gap: 15, height: 44 }}>
        <Button
          variant={hasReceivedHomeHealth === 'yes' ? 'primary' : 'secondary'}
          flex={1}
          onClick={() => setHasReceivedHomeHealth('yes')}
          sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
        >
          Yes - Cancel Visit
        </Button>
        <Button
          flex={1}
          variant={hasReceivedHomeHealth === 'no' ? 'primary' : 'secondary'}
          onClick={() => setHasReceivedHomeHealth('no')}
          sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
        >
          No
        </Button>
      </Flex>
      {hasReceivedHomeHealth === 'no' ? (
        <>
          <Flex sx={{ marginTop: 30 }}>
            <Button
              flex={1}
              variant={'primary'}
              onClick={onProceedWithVisit}
              sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
            >
              Proceed with Visit
            </Button>
          </Flex>
          <p style={{ fontSize: 13 }}>
            <b style={{ color: 'red' }}>Warning:</b> Please be advised that if
            Medicare declines to cover a visit due to concurrent in-home
            services, you will be responsible for the cost of the visit, as
            agreed in your patient consent form. Additionally, in such cases,
            the therapist's compensation for the non-billable visit “may” be
            subject to a clawback.
          </p>
        </>
      ) : null}
      {hasReceivedHomeHealth === 'yes' ? (
        <>
          <Flex sx={{ marginTop: 30 }}>
            <Button
              flex={1}
              variant={'primary'}
              onClick={onCancelVisit}
              sx={{ paddingLeft: 0, paddingRight: 0, fontSize: 13 }}
            >
              Cancel Visit
            </Button>
          </Flex>
          <p style={{ fontSize: 13 }}>
            Operations team will reach out to the patient for further
            coordination.
          </p>
        </>
      ) : null}
    </>
  );
};
