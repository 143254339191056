import React from 'react';
import { Box, Flex, FlexProps } from 'rebass';
import { Field, useFormikContext } from 'formik';
import { LabelledRadio } from 'components/forms/LabelledRadio';

export interface Option {
  label: React.ReactNode;
  value: any;
  subText?: React.ReactNode;
}

type Props = FlexProps & {
  headerText: React.ReactNode;
  options: Option[];
  fieldName: string;
  disabled?: boolean;
};

export const LabelledRadioFieldGroup = ({
  headerText,
  options,
  fieldName,
  disabled = false,
  ...rest
}: Props) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Flex flexDirection="column" padding={3} {...rest}>
      {headerText}
      <Box marginTop={3}>
        {options.map(({ label, value, subText }) => (
          <Box key={label?.toString() || value.toString()} marginBottom={1}>
            <Field
              component={LabelledRadio}
              name={fieldName}
              label={label}
              checkedValue={value}
              disabled={disabled}
            />
            {subText && (
              <Flex
                fontSize="11px"
                m={1}
                color="#555"
                onClick={() => setFieldValue(fieldName, value)}
              >
                {subText}
              </Flex>
            )}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};
