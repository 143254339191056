import { RisePopup } from 'components/RisePopup';
import React, { useState } from 'react';
import { Text } from 'rebass';
import { AppFileType } from 'utils/file/constants';
import { AbnFormRequestFormWrapper } from 'containers/Files/AbnFormRequestButton/AbnFormRequestFormWrapper';

export const AbnFormRequestButton = ({
  leadId,
  caseId,
  appFileType,
  resign,
}: {
  leadId: string;
  caseId: string;
  appFileType: AppFileType;
  resign?: boolean;
}) => {
  const [
    isShowingDataCollectionPopup,
    setIsShowingDataCollectionPopup,
  ] = useState(false);

  const togglePopup = () => {
    setIsShowingDataCollectionPopup(!isShowingDataCollectionPopup);
  };

  return (
    <>
      <Text variant="link" onClick={togglePopup}>
        {resign ? 'Re-sign' : 'Sign'}
      </Text>
      {isShowingDataCollectionPopup ? (
        <RisePopup
          onClickOverlay={togglePopup}
          sx={{
            height: '100%',
            position: 'fixed',
            left: 0,
            bottom: 0,
            zIndex: 9999,
            display: 'flex',
          }}
          render={({ createHideHandler }) => {
            return (
              <AbnFormRequestFormWrapper
                leadId={leadId}
                caseId={caseId}
                appFileType={appFileType}
                onSubmit={createHideHandler}
              />
            );
          }}
        />
      ) : null}
    </>
  );
};
