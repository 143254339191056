export enum ReasonMedicareMayNotPay {
  ExceededMaxTreatment = 'Patient exceeded maximum treatment',
  NotPrescribed = 'This service has not been prescribed by his/her doctor',
  ExcessiveTreatment = 'The beneficiary is extending treatment without Doctor’s consent',
  RequestingAdditionalSessions = 'The beneficiary is requesting additional sessions',
  NotMedicallyNecessary = 'These services are not considered to be Medically Necessary by the Doctor',
}

export interface FormValues {
  reasonMedicareMayNotPay: ReasonMedicareMayNotPay;
  medicareBillingPreference: string;
  physicalTherapyActivity: string;
  additionalInformation: string;
}
