import React, { useCallback } from 'react';
import { Formik } from 'formik';
import {
  FormValues,
  ReasonMedicareMayNotPay,
} from 'containers/Files/AbnFormRequestButton/constants';
import toast from 'react-hot-toast';
import { REQUEST_FORM } from 'modules/files/actionTypes';
import { useConnectionStatus } from 'hooks/useConnectionStatus';
import { useDispatch } from 'react-redux';
import { fileTypeToUploadType } from 'modules/hooks/constants';
import { AppFileType } from 'utils/file/constants';
import { AbnFormRequestForm } from 'containers/Files/AbnFormRequestButton/AbnFormRequestForm';

interface Props {
  leadId: string;
  caseId: string;
  appFileType: AppFileType;
  onSubmit: () => void;
}

const initialValues: FormValues = {
  reasonMedicareMayNotPay: ReasonMedicareMayNotPay.ExceededMaxTreatment,
  medicareBillingPreference: '1',
  physicalTherapyActivity: '',
  additionalInformation: '',
};

export const AbnFormRequestFormWrapper = ({
  onSubmit,
  leadId,
  caseId,
  appFileType,
}: Props) => {
  const { isOnline } = useConnectionStatus();
  const dispatch = useDispatch();

  const fileName = fileTypeToUploadType[appFileType];

  const finalOnSubmit = useCallback(
    values => {
      if (
        !values.physicalTherapyActivity.trim() ||
        !values.medicareBillingPreference.trim() ||
        !values.reasonMedicareMayNotPay.trim() ||
        !parseInt(values.medicareBillingPreference.trim())
      ) {
        toast.error(
          'Please fill all fields before submitting the form request.'
        );
      } else {
        onSubmit();
        dispatch({
          type: REQUEST_FORM,
          payload: {
            leadId,
            caseId,
            form_type: fileName,
            embed: isOnline,
            abnFields: values,
          },
        });
      }
    },
    [caseId, dispatch, leadId, fileName, isOnline, onSubmit]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={finalOnSubmit}>
      {AbnFormRequestForm}
    </Formik>
  );
};
