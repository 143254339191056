import React, { useState } from 'react';

import { CaseFileControls } from 'containers/Files/CaseFileControls';
import { Appointment, Case, Lead, Patient } from 'types/types';
import { AppFileInputType, AppFileType } from 'utils/file/constants';
import { useSelector } from 'react-redux';
import { getIsPaymentCardInputEnabled } from 'selectors/features/getIsPaymentCardInputEnabled';
import { PatientCardLink } from 'components/PatientCardLink/PatientCardLink';
import { Box, Button, Flex, Text } from 'rebass';
import { Textarea } from '@rebass/forms';
import { RisePopup } from './RisePopup';

type Props = {
  lead?: Lead;
  patient?: Patient;
  appointment?: Appointment;
  trackedDocuments?: Array<string>;
  ignoredDocuments?: Array<string>;
  therapyCase?: Case;
  onClickOverlay: () => void;
  onSkipWithErrors: (notes: string) => void;
};

const appFileTypeByMissingDoc: Record<string, AppFileType> = {
  'Payment Method': AppFileType.Payment,
  Signature: AppFileType.FormSignature,
  Initials: AppFileType.Initials,
  'Intake Form': AppFileType.IntakeForm,
  'ABN Form': AppFileType.AbnForm,
  'Lien Form': AppFileType.LienForm,
};

const appFileInputTypeByMissingDoc: Record<string, AppFileInputType> = {
  // Payment Method is handled differently because it's not a file
  Signature: AppFileInputType.FormSignature,
  Initials: AppFileInputType.FormSignature,
  'Intake Form': AppFileInputType.Form,
  'ABN Form': AppFileInputType.AbnForm,
  'Lien Form': AppFileInputType.Form,
};

export const MissingDocumentsPopup = ({
  lead,
  trackedDocuments,
  ignoredDocuments,
  appointment,
  patient,
  therapyCase,
  onClickOverlay,
  onSkipWithErrors,
}: Props) => {
  const [isSkipping, setIsSkipping] = useState(false);
  const [notes, setNotes] = useState('');
  const isPaymentCardInputEnabled = useSelector(getIsPaymentCardInputEnabled);

  const leadMissingDocs = lead?.missingDocs || [];

  let missingDocs = leadMissingDocs;

  if (trackedDocuments?.length) {
    missingDocs = leadMissingDocs.filter(md => trackedDocuments?.includes(md));
  }

  if (ignoredDocuments?.length) {
    missingDocs = leadMissingDocs.filter(md => !ignoredDocuments?.includes(md));
  }

  return (
    <RisePopup
      onClickOverlay={onClickOverlay}
      render={({ createHideHandler }) => {
        return (
          <>
            <Text
              color="#444"
              textAlign="center"
              lineHeight="1.2rem"
              fontSize={1}
              marginBottom="30px"
            >
              You are missing required documents. Please upload them before
              ending the session.
            </Text>
            {missingDocs.map(missingDoc => (
              <Flex
                key={missingDoc}
                alignItems="center"
                marginBottom={2}
                justifyContent="flex-start"
                flexWrap="wrap"
              >
                <Text>{missingDoc}:</Text>
                <Box marginLeft={1}>
                  {missingDoc === 'Payment Method' ? (
                    isPaymentCardInputEnabled ? (
                      <PatientCardLink
                        patient={patient as Patient}
                        appointmentId={String(appointment?.id)}
                      />
                    ) : null
                  ) : (
                    <CaseFileControls
                      appFileType={appFileTypeByMissingDoc[missingDoc]}
                      inputType={appFileInputTypeByMissingDoc[missingDoc]}
                      caseId={String(therapyCase?.id)}
                    />
                  )}
                </Box>
              </Flex>
            ))}
            {isSkipping ? (
              <Box>
                <Text
                  color="#222"
                  textAlign="center"
                  lineHeight="1.2rem"
                  fontSize={1}
                  marginBottom="30px"
                  fontWeight="bold"
                >
                  Kindly provide the reason for not uploading this
                  documentation. Thank you.
                </Text>
                <Textarea
                  sx={{ borderRadius: 4, resize: 'none' }}
                  height={150}
                  onChange={e => {
                    setNotes(e.target.value ? e.target.value.trim() : '');
                  }}
                />
                <Button
                  type="button"
                  marginTop="30px"
                  width="100%"
                  variant="secondary"
                  disabled={!notes}
                  onClick={() => {
                    createHideHandler(() => {
                      if (notes.length) {
                        onSkipWithErrors(notes);
                      }
                    })();
                  }}
                >
                  Skip
                </Button>
                <Button
                  marginTop={30}
                  width="100%"
                  textAlign="center"
                  variant="link"
                  color="#888"
                  onClick={() => {
                    setIsSkipping(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Button
                marginTop="30px"
                variant="link"
                onClick={() => {
                  setIsSkipping(true);
                }}
              >
                Skip this time
              </Button>
            )}
          </>
        );
      }}
    />
  );
};
